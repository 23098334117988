var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "liveTag"
  }, [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleButton("add");
      }
    }
  }, [_vm._v("新增")])], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleButton("edit", row);
            }
          }
        }, [_vm._v("修改")])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle,
      width: 400,
      footer: null,
      centered: ""
    },
    on: {
      cancel: _vm.resetForm
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "liveTagForm",
    attrs: {
      model: _vm.liveTagForm,
      rules: _vm.rules
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "中文：",
      prop: "nameCn"
    }
  }, [_c("a-input", {
    attrs: {
      type: "text",
      "auto-size": "",
      placeholder: "请输入中文招呼内容"
    },
    model: {
      value: _vm.liveTagForm.nameCn,
      callback: function callback($$v) {
        _vm.$set(_vm.liveTagForm, "nameCn", $$v);
      },
      expression: "liveTagForm.nameCn"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "英语：",
      prop: "nameEn"
    }
  }, [_c("a-input", {
    attrs: {
      type: "text",
      "auto-size": "",
      placeholder: "请输入英语招呼内容"
    },
    model: {
      value: _vm.liveTagForm.nameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.liveTagForm, "nameEn", $$v);
      },
      expression: "liveTagForm.nameEn"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "繁体：",
      prop: "nameHk"
    }
  }, [_c("a-input", {
    attrs: {
      type: "text",
      "auto-size": "",
      placeholder: "请输入繁体招呼内容"
    },
    model: {
      value: _vm.liveTagForm.nameHk,
      callback: function callback($$v) {
        _vm.$set(_vm.liveTagForm, "nameHk", $$v);
      },
      expression: "liveTagForm.nameHk"
    }
  })], 1)], 1), _c("a-form-model-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      loading: _vm.btnLoading,
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 保存")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };